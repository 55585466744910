<template>
  <div>
    <TopNav />

    <div class="video-container">
      <video
        autoplay
        controls
        class="video"
        :poster="storageHost + '/videos/patient.jpg'"
      >
        <source :src="storageHost + '/videos/patient.mp4'" type="video/mp4" />
        <source :src="storageHost + '/videos/patient.webm'" type="video/webm" />
      </video>
    </div>

    <v-container
      v-touch="{
        right: () => toHome(),
      }"
    >
      <div class="my-9">
        <div class="d-flex align-center justify-space-between my-6 my-sm-9">
          <div class="text-h3 font-weight-black" data-aos="fade-in">
            Patient
          </div>
          <TextLogo :width="150" accent="#97D7D8" color="#21118D" />
        </div>

        <div class="text-body-1 my-6" data-aos="fade-in" data-aos-delay="100">
          Create your own medical profile and easily request a consultation or
          second opinion by sharing your medical profile with a physician.
        </div>

        <div class="text-body-1 my-6" data-aos="fade-in" data-aos-delay="100">
          Complete medical data mobility that removes any obstacles in the way
          of getting the best medical advice.
        </div>

        <v-list-item data-aos="fade-in">
          <v-list-item-icon>
            <v-icon color="accent">mdi-charity</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >Consult directly with the best medical professionals around the
              world.</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item data-aos="fade-in">
          <v-list-item-icon>
            <v-icon color="accent">mdi-shield-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >Protect your data with military grade
              encryption.</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item data-aos="fade-in">
          <v-list-item-icon>
            <v-icon color="accent">mdi-earth</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >Available anywhere in the world on any device.</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item data-aos="fade-in">
          <v-list-item-icon>
            <v-icon color="accent">mdi-radiology-box</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >Easily upload and view your radiology and medical
              images.</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item data-aos="fade-in">
          <v-list-item-icon>
            <v-icon color="accent">mdi-shield-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >Full autonomy over your personal medical
              files.</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <div class="mt-9">
          <v-btn
            v-if="isIOS"
            large
            elevation="0"
            data-aos="zoom-in"
            rounded
            href="https://apps.apple.com/us/app/patrec/id1565819396"
            target="_blank"
            :block="$vuetify.breakpoint.xsOnly"
            class="mr-sm-2 mb-1 mb-sm-0"
            dark
            color="#97D7D8"
          >
            <v-icon class="mr-1">mdi-apple</v-icon>
            View in App Store
          </v-btn>
          <v-btn
            v-if="isAndroid"
            large
            elevation="0"
            data-aos="zoom-in"
            rounded
            href="https://play.google.com/store/apps/details?id=com.patrec.patient"
            target="_blank"
            :block="$vuetify.breakpoint.xsOnly"
            class="mr-sm-2 mb-1 mb-sm-0"
            dark
            color="#97D7D8"
          >
            <v-icon class="mr-1">mdi-android</v-icon>
            Get it on Google Play
          </v-btn>
          <v-btn
            v-if="!isAndroid && !isIOS"
            large
            elevation="0"
            data-aos="zoom-in"
            rounded
            href="https://patient.pat-rec.com/signup"
            target="_blank"
            :block="$vuetify.breakpoint.xsOnly"
            class="mr-sm-2 mb-1 mb-sm-0"
            dark
            color="#97D7D8"
          >
            <v-icon class="mr-1">mdi-account-plus</v-icon>
            Register
          </v-btn>
          <v-btn
            v-if="!isAndroid && !isIOS"
            large
            elevation="0"
            data-aos="zoom-in"
            rounded
            text
            href="https://patient.pat-rec.com/login"
            target="_blank"
            :block="$vuetify.breakpoint.xsOnly"
            class="mr-sm-2 mb-1 mb-sm-0"
            color="#97D7D8"
          >
            <v-icon class="mr-1">mdi-login</v-icon>
            Login
          </v-btn>
          <v-btn
            large
            elevation="0"
            data-aos="zoom-in"
            rounded
            href="https://calendly.com/patrec-daniel/patrec-demo"
            target="_blank"
            :block="$vuetify.breakpoint.xsOnly"
            text
            color="#97D7D8"
          >
            <v-icon class="mr-1">mdi-calendar-clock</v-icon>
            Book a demo
          </v-btn>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import TextLogo from "@/components/shared/TextLogo";
import TopNav from "@/components/shared/TopNav";

export default {
  components: { TextLogo, TopNav },
  metaInfo: () => ({
    title: "Patient - PatRec - Connecting Healthcare - Holden Knight Group",
  }),
  computed: {
    isAndroid() {
      if (/Android/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    isIOS() {
      if (/iPhone/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      storageHost: process.env.VUE_APP_STORAGE_HOST,
    };
  },
  methods: {
    toHome() {
      this.$router.push({
        name: "Home",
      });
    },
  },
};
</script>

<style scoped>
.text-h3 {
  color: #97d7d8;
}
</style>
